<template>
  <header class="bg-white shadow-md">
    <div class="container mx-auto px-4">
      <div class="flex items-center justify-between py-4">
        <!-- Logo -->
        <div class="flex-shrink-0">
          <img src="https://cdn.arrify.com/wp-content/uploads/2022/05/cropped-Arrify_Text_small.png" alt="Arrify Logo" class="h-8 w-auto">
        </div>

        <!-- Mobile menu button -->
        <div class="md:hidden">
          <button @click="toggleMenu" class="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600">
            <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <path v-if="!isMenuOpen" fill-rule="evenodd" clip-rule="evenodd" d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm0 5h16v2H4v-2z"/>
              <path v-else fill-rule="evenodd" clip-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
            </svg>
          </button>
        </div>

        <!-- Desktop menu -->
        <nav class="hidden md:flex space-x-10">
          <a href="/" class="text-base font-medium text-gray-500 hover:text-gray-900">Home</a>
          <a href="/demo" class="text-base font-medium text-gray-500 hover:text-gray-900">Demo</a>
          <a href="/tools" class="text-base font-medium text-gray-500 hover:text-gray-900">Tools</a>
          <a href="/blog" class="text-base font-medium text-gray-500 hover:text-gray-900">Blog</a>
          <a href="/about" class="text-base font-medium text-gray-500 hover:text-gray-900">About</a>
          <a href="/careers" class="text-base font-medium text-gray-500 hover:text-gray-900">Careers</a>
          <a href="/contact" class="text-base font-medium text-gray-500 hover:text-gray-900">Contact</a>
        </nav>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state -->
    <div v-if="isMenuOpen" class="md:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <a href="/" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Home</a>
        <a href="/blog" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Blog</a>
        <a href="/about" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">About</a>
        <a href="/careers" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Careers</a>
        <a href="/contact" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Contact</a>-
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      isMenuOpen: false
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    }
  }
}
</script>
